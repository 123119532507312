<template>
  <div id="app">
    <Header :key="headerKey" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { EventBus } from './event-bus.js';
const newOrderNotifSound = require("@/assets/to-the-point.mp3");



export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      headerKey: 0,
      url: process.env.VUE_APP_API_URL
    }
  },
  mounted() {
    EventBus.$on('user_signed_in', signedIn => {
      console.log('User signed in')
      this.headerKey += 1;
    });
    if (localStorage.signedIn) {
      this.$http.secured.get('/app_current_user')
        .then(response => { this.currentUser = response.data.user,
            this.user_type = response.data.user_type
            var channel = this.$pusher.subscribe('orders-channel');
            var helpRequestsChannel = this.$pusher.subscribe('help-channel');
            console.log(this.currentUser)
            channel.bind(String(this.currentUser), data => {
                console.log(data)
                this.postOrder();
                })  
            helpRequestsChannel.bind(String(this.currentUser), data => {
                console.log(data)
                this.postHelpRequest();
                // this.postOrder();
                })     
              
            })
        .catch(error => this.setError(error, 'Something went wrong'))
      }
    },
    methods: {
      postOrder() {
          this.playNewOrderSound();
          EventBus.$emit('order_received', this.orderReceived);
      },
      postHelpRequest() {
          this.playNewOrderSound();
          EventBus.$emit('help_request_received', this.helpRequestReceived);
      },
      playNewOrderSound() {
          var audio = new Audio(newOrderNotifSound); 
          audio.play();
      }
    }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;800&display=swap');

a {
  text-decoration: none;
  color: black;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

.export-to-csv {
    width: 100%;
    border: 1px solid lightgray;
    margin-top: 50px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12.5px;
    /* margin-bottom: 100px; */
}

.delete-button {
  border: 1px solid darkred;
  box-sizing: border-box;
  padding: 2.5px;
  border-radius: 2.5px;
  font-size: 10px;
  display: inline-flex;
  color: darkred;
  cursor: pointer;
}

.delete-button:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
}

.export-to-csv:hover {
    cursor: pointer;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

/* graph styles */

.timeframe-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 12.5px;
    font-weight: 600;
    color: gray;
}

.timeframe-button {
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    width: 95px;
    text-align: center;
    white-space: nowrap;
}

.x_axis {
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    box-sizing: border-box;
    padding-top: 5px;
    /* padding-right: 5px; */
}

.x_axis div {
    width: auto;
    display: inline-flex;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    white-space: nowrap;
}

.thirty-days {
    visibility: hidden;
}

.thirty-days:nth-child(3n) {
    visibility: visible;
    margin-left: 0px;
}

.graph-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 50px;
}

.graph-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: auto;
    min-height: 200px;
    justify-content: flex-end;
}

.back-refer {
  display: flex;
  border: 1px solid lightgray;
  border-radius: 2.5px;
  box-sizing: border-box;
  padding: 2.5px 5px 2.5px 5px;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
  width: max-content;
  letter-spacing: 0.5px;
  margin: 0px 0px 25px 0px;
  cursor: pointer;
}


/* loaders */
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.mini-link {
  width: 100%;
  background-color: #27A9E1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  font-weight: 600;
  font-size: 12.5px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
}

.new-create-button {
  width: 100%;
  border: 1px solid #27A9E1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #27A9E1;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
  margin: 0px 0px 0px 0px;
  cursor: pointer; 
}

.tag {
    display: flex;
    width: max-content;
    font-size: 10px;
    font-weight: 600;
    color: white;
    box-sizing: border-box;
    margin: 0px 0px 0px 0px;
    padding: 2.5px 5px 2.5px 5px;
    border-radius: 2.5px;
    margin-bottom: 10px;
}

.tag p {
  width: max-content;
}

.green {
  background-color: #8CC63E;
}

.blue {
  background-color: #27A9E1;
}

.back-button {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 2.5px;
  width: max-content;
  padding: 5px 25px 5px 25px;
  margin-bottom: 50px;
  align-items: center;
  cursor: pointer;
}

.back-button img {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-bottom: 5px;
  background-color: white;
  box-sizing: border-box;
  padding: 2.5px;
}

.transparent-blue {
  background-color: rgba(39, 169, 225, 0.2);
}

.blue-text {
  color: #27A9E1;
}

/* Toggle button styles */

.toggle-button-container {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-button-container p {
  font-size: 12.5px;
  font-weight: 600;
}

.toggle-button-circular {
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  min-height: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #808284;
  margin-right: 10px;
}

.toggle-button-circular:hover {
  opacity: 0.8;
  transition: 0.2s;
}

.toggle-button-inset {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.toggle-button-active {
  background-color: #8CC63E;
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #137444;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


@media(max-width: 600px) {
      .fourteen-days {
        visibility: hidden;
    }

    .fourteen-days:nth-child(2n) {
        visibility: visible;
    }

    .timeframe-button {
        font-size: 10px;
    }

    .thirty-days {
        visibility: hidden;
    }

    .thirty-days:nth-child(3n) {
        visibility: hidden;
    }

    .thirty-days:nth-child(5n) {
        visibility: visible;
    }

    .graph-column {
        min-height: 100px;
    }
}

</style>
