

const state = () => ({
    logged_in: Boolean,
    current_user: Number,
    user_type: '',
    currently_viewed_user: 0
})


const mutations = {
    setLoggedIn (state, current_user) {
        state.logged_in = true
        // state.current_user = current_user
    },
    setCurrentUserId (state, current_user) {
        state.current_user = current_user
    },
    setUserType (state, user_type) {
        state.user_type = user_type
    },
    setLoggedOut (state) {
        state.logged_in = false,
        state.current_user = null,
        state.user_type = ''
    },
    setCurrentlyViewedUser (state, currently_viewed_user) {
        state.currently_viewed_user = currently_viewed_user
    }
}

const getters = {
    getLoggedIn (state) {
        return state.logged_in
    },
    getUserId (state) {
        return state.current_user
    },
    getUserType (state) {
        return state.user_type
    },
    getCurrentlyViewedUser (state) {
        return state.currently_viewed_user
    }
}


export default {
    state,
    getters,
    mutations
}