<template>
   
   <div id="header">
        <router-link class='header-icon' to="/">
          <img src='@/assets/menulingua_logo_final.svg' />
        </router-link>
        <router-link to="/orders" class="navbar-link" v-if="signedIn()"><img src="@/assets/order_plate_black.svg" />Orders
          <div v-if="active_order_count > 0" class="notif-circle">{{ active_order_count }}</div></router-link>
        <router-link to="/analytics" class="navbar-link" v-if="signedIn()"><img src="@/assets/analytics.svg" />Analytics</router-link>
        <div class="filler"></div>
        <router-link to="/" class="navbar-link" v-if="!signedIn()">Sign in</router-link>
        <a class="navbar-link" href="#" @click.prevent="signOut" v-if="signedIn()">Sign out</a>
    </div>

</template>

<script>
import { EventBus } from '../event-bus.js'

export default {
  name: 'Header',
  data() {
    return {
      active_notifications: false,
      active_order_count: 0
    }
  },
  created () {
    this.signedIn()
  },
  mounted() {
    EventBus.$on('order_received', orderReceived => {
      this.active_order_count = this.active_order_count + 1
      // var audio = new Audio(newOrderNotifSound); 
      // audio.play();
    });
    EventBus.$on('help_request_received', helpRequestReceived => {
      this.active_order_count = this.active_order_count + 1
      // var audio = new Audio(newOrderNotifSound); 
      // audio.play();
    });
    EventBus.$on('order_seen', orderSeen => {
      if (this.active_order_count > 0) {
        this.active_order_count = this.active_order_count - 1
      }
    });
  },
  methods: {
    setError (error, text) {
      this.error = (error.response && error.response.data && error.response.data.error) || text
    },
    signedIn () {
      return localStorage.signedIn
    },
    signOut () {
      this.$http.secured.delete('/app_session_delete')
        .then(response => {
          delete localStorage.token
          delete localStorage.csrf
          delete localStorage.signedIn
          delete localStorage.refresh
          this.$forceUpdate()
          this.$router.replace('/')
        })
        .catch(error => this.setError(error, 'Cannot sign out'))
    }
  }
}

</script>


<style scoped>

#header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
    height: 75px;
}

#header h1 {
    font-weight: 200;
    margin: 0px 0px 15px 5px;
    font-size: 20px;
}

.header-icon {
    height: 75px;
    width: 200px;
    max-height: 75px;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0px 30px 0px 30px;
}

.header-icon img {
  max-height: 75px;
  max-width: 200px;
  height: 30px;
  width: 200px;
}

.header-icon:hover {
  background-color: #cfe3b1;
  transition: 0.2s;
}

.navbar-link {
    /* border-left: 1px solid lightgray; */
    font-size: 12.5px;
    height: 75px;
    display: flex;
    align-items: center;
    font-weight: 600;
    box-sizing: border-box;
    padding: 0px 30px 0px 30px;
    border-left: 1px solid lightgray;
    position: relative;
}

.navbar-link:nth-child(3) {
  border-right: 1px solid lightgray;
}

.navbar-link:hover {
  background-color: #cfe3b1;
  /* border-left: 1px solid #cfe3b1; */
  /* border-right: 1px solid #cfe3b1; */
  transition: 0.2s;
  /* color: white; */
}

.navbar-link img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.filler {
    flex-grow: 1;
}

.notif-circle {
  background-color: #9E1E62;
  color: white;
  width: 20px;
  height: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 5px;
  right: 5px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

@media(max-width: 600px) {

  #header {
    justify-content: space-between;
  }

  .header-icon img {
    width: 100%;
  }

  .navbar-link {
    border: 0px !important;
    font-size: 10px;
    box-sizing: border-box;
    padding: 0px;
    flex-grow: 1;
    white-space: nowrap;
    display: flex;
    justify-content: center;
  }

  .filler {
    display: none;
  }

  .navbar-link img {
    display: none;
  }

  .navbar-link:hover {
    border: 0px;
  }

}

@media(max-width: 425px) {
  .header-icon {
    width: 150px;
    max-width: 150px;
  }

  /* .navbar-link {
    border: 0px !important;
    font-size: 8px;
    padding-left: 5px;
    padding-right: 5px;
  } */

}

@media print {
  #header * {
    visibility: hidden;
  }
}





</style>