import { render, staticRenderFns } from "./CreateServer.vue?vue&type=template&id=5177e33e&scoped=true&"
import script from "./CreateServer.vue?vue&type=script&lang=js&"
export * from "./CreateServer.vue?vue&type=script&lang=js&"
import style0 from "./CreateServer.vue?vue&type=style&index=0&id=5177e33e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5177e33e",
  null
  
)

export default component.exports