import Vue from 'vue'
import Vuex from 'vuex'
import currentuser from './modules/currentuser'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    currentuser: currentuser
  }
})
