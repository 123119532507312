<template>

    <div id="orders-analytics-container">

        <h2>Orders</h2>
        
        <div class="timeframe-buttons">

            <div @click="setTimeFrame(7)" :class="[{ 'selected' : timeframe == 7 }, 'timeframe-button']">

                Past 7 days

            </div>  

            <div @click="setTimeFrame(14)" :class="[{ 'selected' : timeframe == 14 }, 'timeframe-button']">

                Past 14 days

            </div>    

            <div @click="setTimeFrame(30)" :class="[{ 'selected' : timeframe == 30}, 'timeframe-button']">

                Past 30 days

            </div>

        </div>

        <div class="timeframe-buttons" v-if="timeframe != 'all_time'">

            <div @click="setVisualization('grand_total')" :class="[{ 'selected' : visualization == 'grand_total'}, 'timeframe-button']">  

                Customer spend

            </div>

            <div @click="setVisualization('pax')" :class="[{ 'selected' : visualization == 'pax'}, 'timeframe-button']">  

                Pax

            </div>

        </div>

        <div v-if="this.is_loading === true" class="loader">

            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div>

        <div v-else>

            <div class="no-orders" v-if="zero_orders_in_timeframe == true">

                No orders... yet!

            </div>            

            <div class="graph-body" v-if="timeframe != 'all_time'"> 

                <div v-for="date in dates" :key="date" :style="getWidth()" class="graph-column">

                    <div class="count">
                        <span>{{ datestamp(date) }}</span>
                        <p v-if="filteredOrders(date).length === 1"> {{ filteredOrders(date).length }} order</p>
                        <p v-else> {{ filteredOrders(date).length }} orders</p>
                        <h6 class="daily-spend">{{ currency }} {{ dailySpend(filteredOrders(date)) }} </h6>
                    </div>

                    <a v-bind:href="'/orders/'+ order.id" v-for="order in filteredOrders(date)" :key="order.id" class="order" :style="getHeight(order)">

                        <div class="extra-info">

                            <h5>#{{ order.id }}</h5>

                            <p>{{ timestamp(order.created_at) }}</p>

                            <p><img src="@/assets/pax_black.svg" />{{ order.pax }}</p>

                            <p><img src="@/assets/table_black_v2.svg" />{{ order.table_number }} </p>
                                
                            <h6>{{ order.currency}} {{ numberFormat(order.currency, order.grand_total) }}</h6>

                        </div>

                    </a>

                </div>

            </div>

            <div class="x_axis" v-if="timeframe != 'all_time'">

                <div v-for="date in dates" :key="date" :style="getWidth()" :class="[{ 'thirty-days' : timeframe == 30, 'fourteen-days' : timeframe == 14, 'seven-days' : timeframe == 7 }, 'date-text', getEvenOddDate() ]">

                    {{ datestamp(date) }}
                    
                </div> 

            </div> 

            <div class="most-popular-items-container">

                <h3>Items ordered ({{ timeframeShortForm(timeframe) }})</h3>

                <div class="table-header">

                    <p>Item</p>

                    <p>Orders</p>

                </div>

                <div class="no-data" v-if="zero_orders_in_timeframe == true">

                    No data... yet!

                </div>                

                           

                <div v-for="line_item in countedOrderRows(order_rows)" :key="line_item.id" class="food-row">

                    <div class="food-info-container">

                        <div v-if="foods.filter((food) => food.id === line_item.food_id).length === 0" class="deleted-item">
                            
                            Deleted item

                        </div>

                        <div v-else class="food-info">

                            <p class="food-name">{{ ((foods.filter((food) => food.id === line_item.food_id))[0].translation_en || (foods.filter((food) => food.id === line_item.food_id))[0].name) }}</p>

                            <span v-if="line_item.bundle_item === true" class="food-add-on">Bundle</span>

                            <p v-if="line_item.item_unit" class="food-add-on">{{ line_item.item_unit }}</p>

                            <p v-if="line_item.item_selection_name" class="food-add-on">{{ line_item.item_selection_name }}</p>

                        </div>



                    </div>

                    <p class="food-count">{{ line_item.item_count }}</p>
                    
                </div>

            </div>

        </div>

    </div>



</template>



<script>
import moment from 'moment'

export default {
    name: 'OrdersAnalytics',
    data () {
        return {
            timeframe: 14,
            orders: [],
            dates: [],
            foods: [],
            order_rows: [],
            is_loading: true,
            visualization: 'grand_total',
            zero_orders_in_timeframe: false,
            currency: '',
            user_type: '',
            viewed_user: ''
        }
    },
    props: ['user'],
    created() {
        this.getData();
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        getData() {
            let timeframe = this.timeframe;
            this.$http.secured.get('/app_orders_analytics/' + timeframe + '/' + this.user)
                .then(response => { 
                    this.orders = response.data.orders,
                    this.order_rows = response.data.order_rows,
                    this.getCurrency(this.orders)
                    this.dates = response.data.dates,
                    this.foods = response.data.foods,
                    this.checkOrdersCount();
                    this.is_loading = false;
                }).catch(error => this.setError(error, 'Something went wrong'))
        },
        checkOrdersCount() {
            if (this.orders.length === 0) {
                this.zero_orders_in_timeframe = true
            } else {
                this.zero_orders_in_timeframe = false
            }
        },
        getCurrency(orders) {
            if (this.orders.length > 0) {
                this.currency = this.orders[this.orders.length - 1].currency
            } else {
                this.currency = ''
            }
        },
        setTimeFrame(timeframe) {
            this.is_loading = true;
            this.timeframe = timeframe;
            this.getData();
            this.getWidth();
        },
        setVisualization(visualization) {
            this.visualization = visualization
        },
        getWidth() {
            if (this.timeframe != 'all_time') {
                var percent = (100 / this.timeframe)
                return { 
                    width: percent + '%'
                }
            }
        },
        getEvenOddDate() {
            var time = new Date().getDate();
            if (time % 2 != 0) {
                return 'odd'
            } else  {
                return 'even'
            }
        },
        timestamp(scan_placed) {
            return moment(scan_placed).format('LT');
        },
        datestamp(scan_placed) {
            return moment(scan_placed).format('MMM D');
        },
        timeframeShortForm(timeframe) {
            if (timeframe == 7) {
                return 'past 7 days'
            }
            if (timeframe == 14) {
                return 'past 14 days'
            }
            if (timeframe == 30) {
                return 'past 30 days'
            } 
            if (timeframe == 'all_time') {
                return 'all time'
            }                              
        },
        filteredOrders(date_in_question) {
            var refined_date = moment(date_in_question).format('MMM D');
            return this.orders.filter((e) => moment(e.created_at).format('MMM D') === refined_date)
        },
        dailySpend(orders) {
            var total = 0
            var currency = ''

                for (let i = 0; i < orders.length; i++) {
                    total = total + orders[i].grand_total
                    currency = orders[i].currency
                }

            return this.numberFormat(currency, total)
        },
        getMax() {
            var array = this.orders
            if (this.visualization == 'grand_total') {
                return Math.max.apply(Math, array.map(function(o) { return o.grand_total; }))
            }
            if (this.visualization == 'pax') {
                return Math.max.apply(Math, array.map(function(o) { return o.pax; }))
            }
        },
        getHeight(order) {
            if (this.visualization == 'grand_total') {
                return { 
                    height: ((order.grand_total /  this.getMax()) * 50) + 'px'
                }
            } else {
                return { 
                    height: ((order.pax /  this.getMax()) * 50) + 'px'
                }
            }
        },
        numberFormat(currency, number) {
            if (currency === 'OMR') {
                return Number(number).toFixed(3)
            } else if (currency === 'Rp') {
                return Number(number).toLocaleString()
            } else {
                return Number(number).toFixed(2)
            }
        },
        countedOrderRows(array) {
            const result = [];
            const map = new Map();
            for (const item of array) {
                let count = 0
                let food_id = item.food_id
                let selection_name = item.selection_name
                let unit = item.unit
                for (let i = 0; i < array.length; i++) {
                    if ((array[i].food_id === item.food_id && (array[i].selection_name === item.selection_name) && (array[i].unit === item.unit))) count++;
                }
                if(!map.has(item.food_id  + item.selection_name + item.unit)) {
                    map.set((item.food_id  + item.selection_name + item.unit), true); // set any value to Map
                    result.push({
                        food_id: item.food_id,
                        item_name: item.item_name,
                        item_count: count,
                        item_unit: item.unit,
                        item_selection_name: item.selection_name,
                        item_price: item.item_price,
                        item_base_price: item.base_price,
                        bundle_item: item.bundle_item,
                    });
                }
            }
            return result.sort((a, b) => (a.item_count < b.item_count) ? 1 : -1)
        }
    }
}


</script>


<style scoped>

#orders-analytics-container {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

#orders-analytics-container h2 {
    font-weight: 600;
    font-size: 13px;
    box-sizing: border-box;
    border: 1px solid gray;
    padding: 5px;
    display: inline-flex;
    width: 60px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.selected {
    border: 1px solid #4394ce;
    color: white;
    background-color: #4394ce;
}
.order {
    width: 10px;
    /* height: 10px; */
    margin-top: 1px;
    background-color: #4394ce;
    cursor: pointer;
    border: 1px solid rgba(0 ,0 , 0, 0);
    position: relative;
}

.order:hover {
    border: 1px solid black;
    border-radius: 2.5px;
}

.extra-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 15px;
    top: -7.5px;
    width: 100px;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 5px;
    display: none;
    padding: 10px;
    z-index: 100;
}

.extra-info h5 {
    font-size: 12.5px;
    font-weight: 600;
    margin: 0px 0px 5px 0px;
}

.extra-info h6 {
    font-size: 11px;
    font-weight: 600;
    box-sizing: border-box;
    padding-top: 2px;
    border-top: 1px dotted lightgray;
    margin: 10px 0px 0px 0px;
    
}

.extra-info p {
    margin: 2.5px 0px 2.5px 0px;
    font-size: 11px;
    display: flex;
    align-items: center;
}

.extra-info p img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.order .extra-info {
    position: absolute;
    z-index: 50;
}
.order:hover .extra-info {
    position: absolute;
    display: flex;
    background-color: white;
}

.timeframe-buttons {
    margin-bottom: 10px;
}

.timeframe-button {
    width: 150px;
}

.most-popular-items-container{
    width: 100%;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 30px;
    border-radius: 10px;
    margin-top: 50px;
    min-height: 200px;
}

h3 {
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    font-size: 15px;
}

.table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 11px;
    text-transform: uppercase;
    margin: 0px 0px 0px 0px;
    font-weight: 400;
}

.food-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 0px 0px;
    box-sizing: border-box;
    padding: 20px 0px 20px 0px;
    /* height: 100px !important; */
    /* border-top: 1px solid lightgray; */
    /* border-bottom: 1px dotted lightgray; */
}

.food-row:nth-child(even) {
    border-bottom: 1px dotted lightgray;
    border-top: 1px dotted lightgray;
}

.food-row:first-child {
    border-top: 0px solid white;
}

.food-row:last-child {
    border-bottom: 1px dotted white;
}


.food-info-container {
    font-size: 12.5px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.food-name {
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
}

.food-add-on {
    font-weight: 400;
    font-size: 10px;
    margin: 0px 0px 0px 10px;
    /* background-color: #4394ce; */
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 2.5px;
    border-radius: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-weight: 600;
}

.food-count {
    font-size: 12.5px;
    font-weight: 600;
    margin: 5px 0px 5px 0px;
}

.bundle-item {
    border: 1px solid lightgray;
    color: black;
    font-size: 10px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 2.5px;
    border-radius: 2.5px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.no-orders {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 90%;
    height: 200px;
    margin: auto;
}

.no-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 90%;
    height: 100px;
}

.graph-column:hover {
    background-color: #D8E7F4;
}

.count {
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid gray;
    visibility: hidden;
    margin-bottom: 2.5px;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    bottom: 30px;
    top: -70px;
    /* max-width: 125px; */
    max-height: 60px;
    /* margin-bottom: 30px; */
}

.count span {
    font-size: 10px;
    color: black;
    font-weight: 400;
    text-transform: uppercase;
}

.count p {
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    color: black;
}

.graph-column:hover .count {
    visibility: visible;
}

.daily-spend {
    max-width: 75px;
    margin: 5px 0px 0px 0px;
    font-weight: 600;
}

.food-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.deleted-item {
    /* background-color: gray; */
    width: max-content;
    color: gray;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    font-size: 10px;
    font-weight: 600;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    /* font-size: 10px; */
}

</style>