<template>

    <div id="my-tables">

        <div class="back-button transparent-blue blue-text" @click="returnToOrders()">
            <img src="@/assets/back_arrow_blue.svg" />
            Orders
        </div>

        <h1>My tables</h1>

        <div class="toggle-button-container" @click="toggleTableNumberCapabilities()">

            <div class="toggle-button-circular">
                <div class="toggle-button-inset" :class="{ 'toggle-button-active' : allow_table_number_editing === true }"></div>
            </div>

            <p>Allow customers to manually change table number before submitting order</p>


        </div>

        <div class="admin-input" v-if="user_type === 'admin'">

            <p>Change order recipient ID (admin only)</p>

            <input v-model="order_recipient_id" @change="updateUserId(), saveId(order_recipient_id)">

        </div>

        <div class="tables-container">

            <div v-for="table in parsedTables()" :key="table.id" class="table" @click="editTable(table.id)">

                <img src="@/assets/table.svg" />

                <p class="table-number">{{ table.table_number }}</p>

                <p class="mini-link">Table info</p>

            </div>

        </div>

        <div class="new-create-button" @click="createTable()">Create a new table</div>

    </div>




</template>

<script>

export default {
    name: 'MyTables',
    data () {
        return {
            tables: [],
            user_id: Number,
            is_loading: true,
            order_recipient_id: Number,
            allow_table_number_editing: Boolean
        }
    },
    created() {
        this.scrollToTop();
        this.getUserInfo()
        this.getMyTables()
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        getUserInfo() {
            this.user_type = this.$store.getters.getUserType || localStorage.user_type
            this.user_id = Number(localStorage.user_id)
            if (this.$store.getters.getCurrentlyViewedUser != 0) {
                this.order_recipient_id = this.$store.getters.getCurrentlyViewedUser
            } else {
                this.order_recipient_id = Number(this.user_id)
            }
        },
        getMyTables() {
            this.$http.secured.get(`/app_get_tables/${this.order_recipient_id}`)
                .then(response => { this.tables = response.data.tables, this.allow_table_number_editing = response.data.allow_table_number_editing, this.is_loading = false })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        toggleTableNumberCapabilities() {
            this.$http.secured.post(`/app_toggle_table_number_editing_capabilities/${this.order_recipient_id}`)
                .then(response => { this.allow_table_number_editing = response.data.allow_table_number_editing })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        updateUserId() {
            this.is_loading = true
            this.getMyTables();
        },
        saveId(order_recipient_id) {
            console.log(`Committing: ${ order_recipient_id}`)
            this.$store.commit('setCurrentlyViewedUser', Number(order_recipient_id))
        },
        createTable() {
            this.$router.replace('/new_table')
        },
        editTable(table_id) {
            this.$router.replace(`/tables/${table_id}`)
        },
        parsedTables() {
            var tables = this.tables
            var parsedTables = []
            for (let i = 0; i < tables.length; i++) {
                if (tables[i].deleted === false) {
                    parsedTables.push(tables[i])
                }
            }
            return parsedTables
        },
        returnToOrders() {
            this.$router.replace('/');
        }
    }

}




</script>

<style scoped>

#my-tables {
    display: flex;
    width: 90%;
    max-width: 1200px;
    margin: auto;
    flex-direction: column;
    margin-top: 50px;
}

#my-tables h1 {
    font-weight: 400;
}

input {
    width: 100%;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    height: 45px;
    font-size: 25px;
    border: 1px solid lightgray;
    font-weight: 200;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
}

.admin-input p {
    font-size: 12.5px;
    font-weight: 600;
    box-sizing: border-box;
    padding-top: 20px;
    border-top: 1px dotted lightgray;
    width: max-content;
}

.tables-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 50px;
}

.table {
    width: max-content;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    margin-right: 30px;
    margin-bottom: 30px;
    min-width: 150px;
    cursor: pointer;
}

.table-number {
    font-weight: 600;
    font-size: 20px;
}

.table img {
    width: 20px;
    height: 20px;
}

.toggle-button-container p {
    width: 80%;
}


</style>