<template>

    <CreateServer :editing="true" :id="this.$router.currentRoute.params.id" />

</template>

<script>
import CreateServer from '@/components/CreateServer.vue'

export default {
    name: 'EditServer',
    components: {
        CreateServer
    }
}


</script>

<style scoped>




</style>