<template>

    <div>

        <h2>My menus</h2>

        <div v-if="this.is_loading === true" class="loader">

            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div>

        <div v-else>

            <div v-if="menus.length > 0">

                <a v-bind:href="'/menu_settings/'+ menu.id" class="menu-box" :value="menu.id" v-for="menu in menus" :key="menu.id">

                    <img class="menu-logo" v-bind:src="menu.logo.url" v-if="menu.logo.url != null" /> 

                    {{ menu.name }}

                    <span class="filler"></span>

                    <img src='@/assets/arrow_forward_black.svg' />
                
                </a>

            </div>

            <div v-else>

                No menus yet

            </div>

        </div>

    </div>


</template>


<script>

export default {
    name: 'MyMenus',
    data() {
        return {
            menus: {},
            is_loading: true,
            url: process.env.VUE_APP_API_URL
        }
    },
    created() {
        if (!localStorage.signedIn) {
            this.$router.replace('/')
        } else {
        this.$http.secured.get('/app_get_my_menus')
            .then(response => { this.menus = response.data, this.is_loading = false })
            .catch(error => this.setError(error, 'Something went wrong'))
        }        
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        }
    }
}


</script>

<style scoped>

h2 {
    font-weight: 600;
}

.menu-box {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 10px;
    /* border: 1px solid lightgray; */
    /* border-radius: 10px; */
    border-bottom: 1px dotted lightgray;
    /* margin-bottom: 20px; */
    align-items: center;
    justify-content: space-between;
    height: 74px;
    /* border-left: 1px dotted lightgray; */
    cursor: pointer;
}

.menu-box:first-child {
    border-top: 1px dotted lightgray;
}

.menu-logo {
    margin-right: 10px;
}

.filler {
    flex-grow: 1;
}

.menu-box:hover {
    background-color: #cfe3b1;
    /* border-left: 1px solid #cfe3b1; */
    transition: 0.2s;
    /* box-shadow: 0 0 16px rgba(0, 0, 0, 0.1); */
}

.menu-box img {
    width: 30px;
}


</style>