<template>

    <div id="menu-container">

        <h2>{{ menu_name }}</h2>

        <div :class="{ 'menu-item category-header' : menu_item.category_header == true, 'menu-item ordinary-menu-item' : menu_item.category_header == false }" v-for="menu_item in menu_items" :key="menu_item.id">

            <h3 v-if="menu_item.category_header === true"> {{ menu_item.translation_en || menu_item.name }} </h3>

            <p v-if="menu_item.category_header === false"> {{ menu_item.translation_en || menu_item.name }} </p>

            <div v-if="menu_item.category_header === false" class="availability">

                <div class="sold-out-status">
                    
                    <p v-if="itemIsAvailable(menu_item.id)" @click="setItemToSoldOut(menu_item.id)" class="available-item">
                        <img src="@/assets/check_mark_white.svg" />
                        Available
                    </p>
                    
                    <p v-else @click="setItemToAvailable(menu_item.id)" class="sold-out-item">
                        <img src="@/assets/x_button.svg" />
                        Sold out
                    </p>

                </div>

                <div class="visibility-status">

                    <p v-if="itemIsVisible(menu_item.id)" @click="setItemToHidden(menu_item.id)" class="visible-item">
                        <img src="@/assets/visible.svg" />
                        Visible
                    </p>

                    <p v-else @click="setItemToVisible(menu_item.id)" class="hidden-item">
                        <img src="@/assets/hidden.svg" />
                        Hidden
                    </p>

                </div>


            </div>

        </div>

    </div>


</template>


<script>

export default {
    name: 'Menu',
    data () {
        return {
            menu_name: '',
            menu_items: {},
            sold_out_items: [],
            hidden_items: []
        }
    },
    created() {
        if (!localStorage.signedIn) {
            this.$router.replace('/')
        } else {
            let id = this.$router.currentRoute.params.id;
            this.$http.secured.get('/app_get_menu_items/' + id)
                .then(response => { 
                    this.menu_name = response.data.menu_name,
                    this.menu_items = response.data.menu_items,
                    this.sold_out_items = response.data.sold_out_items,
                    this.hidden_items = response.data.hidden_items
                })
                .catch(error => this.setError(error, 'Something went wrong'))
        }
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        itemIsAvailable(id) {
            if (this.sold_out_items.includes(id)) {
                return false 
            } else {
                return true
            }
        },
        itemIsVisible(id) {
            if (this.hidden_items.includes(id)) {
                return false 
            } else {
                return true
            } 
        },
        setItemToSoldOut(food_id) {
            // var food_id_loading = true
            // this.sold_out_items.push(response.data.food_id)
            let menu_id = this.$router.currentRoute.params.id;
            this.$http.secured.post('/app_create_sold_out_item/' + menu_id + '/' + food_id)
            .then(response => {
                this.sold_out_items.push(response.data.food_id)
            })
            .catch(error => this.setError(error, 'Cannot update item status'))
        },
        setItemToAvailable(food_id) {
            let menu_id = this.$router.currentRoute.params.id;
            this.$http.secured.post('/app_remove_sold_out_item/' + menu_id + '/' + food_id)
            .then(response => {
                var index = this.sold_out_items.indexOf(food_id);
                if (index > -1) {
                    this.sold_out_items.splice(index, 1)
                }
            })
            .catch(error => this.setError(error, 'Cannot update item status'))
        },
        setItemToHidden(food_id) {
            let menu_id = this.$router.currentRoute.params.id;
            this.$http.secured.post('/app_create_hidden_item/' + menu_id + '/' + food_id)
            .then(response => {
                this.hidden_items.push(response.data.food_id)
            })
            .catch(error => this.setError(error, 'Cannot update item status'))
        },
        setItemToVisible(food_id) {
            let menu_id = this.$router.currentRoute.params.id;
            this.$http.secured.post('/app_remove_hidden_item/' + menu_id + '/' + food_id)
            .then(response => {
                var index = this.hidden_items.indexOf(food_id);
                if (index > -1) {
                    this.hidden_items.splice(index, 1)
                }
            })
            .catch(error => this.setError(error, 'Cannot update item status'))
        },        
    }
}



</script>


<style scoped>

#menu-container {
    width: 60%;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    margin-left: 20px;
}

#menu-container h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px 20px 0px;
}

.menu-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    min-height: 100px;
    box-sizing: border-box;
    padding: 10px 0px 10px 0px;
}

.ordinary-menu-item {
    border-bottom: 1px dotted lightgray;
}

.menu-item:last-child {
    border-bottom: 0px;
}

.menu-item h3 {
    font-weight: 600;
    font-size: 17.5px;
}

.menu-item p {
    font-size: 15px;
}

.availability {
    width: 100px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    /* border: 1px dotted lightgray; */
}

.availability p {
    font-size: 12.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    width: 100px;
    height: 35px;
    letter-spacing: 0.5px;
}

.available-item {
    background-color: #8cc63e;
    color: white;
}

.sold-out-item {
    border: 1px solid lightgray;
    color: gray;
    overflow: hidden;
    white-space: nowrap;
}

.availability p img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.visible-item {
    background-color: #1b75bb;
    color: white;
}

.hidden-item {
    border: 1px solid lightgray;
    color: gray;
}

@media(max-width: 1000px) {
    #menu-container {
        width: 50%;
    }
}

@media(max-width: 750px) {
    #menu-container {
        width: 100%;
        margin-left: 0px;
    }
}



</style>