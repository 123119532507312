<template>

    <div class="tutorial">
        <h1>Connect to POS printer (Android, ESC/POS Protocol)</h1>

        <h2>1. Download RawBT print service in Google Play Store.</h2>

        <p>RawBT serves as a universal printer driver for Android devices. <span>In most cases, RawBT is required to connect your printer to the Menulingua Order Dashboard Android App</span>.</p>

        <img src="@/assets/gplay.jpg" />

        <p>The RawBT print service app requires an in-app purchase of an annual license to function properly ($5.99 USD). <span>Menulingua will cover the price of the annual RawBT print service subscription for up to 1 (one) device</span>. Send a screenshot of your Google play purchase receipt to hello@menulingua.com and we will add a credit of $5.99 USD to your Menulingua account. This credit will be applied to your next Menulingua subscription renewal. </p>

        <p>To purchase the one year license, open RawBT on the device you would like to print from. Click on <span>License</span> in the left dropdown menu, as pictured below. Purchase a one-year license through Google Play.</p>

        <img src="@/assets/license_screen.jpg" />

        <h2>2. Connect your printer to RawBT.</h2>

        <p>In the RawBT app, navigate to <span>settings</span> and fill in the relevant details for your printer. To find details including connection type and printer driver, consult your printer documentation.</p>

        <img src="@/assets/rawbt.jpg" />

        <h2>3. Print the order.</h2>

        <p>In the <span>Menulingua Order Dashboard app</span>, open the order page for the order you would like to print. Tap on <span>"PRINT ORDER"</span> at the bottom of the page.</p>

        <img src="@/assets/inapp.jpg" />

        <p><span class="important">IMPORTANT</span><span>Be sure "RAWBT" is chosen as your selected printer</span>. </p>

        <img src="@/assets/printspooler.jpg" />

        <p><span>Tap the "print" icon to the top right</span> (seen in yellow in screenshot above). The order will now print.</p>

        <h5>Questions or issues? Reach out to us at hello@menulingua.com</h5>
    </div>




</template>


<script>
export default {
    name: 'PrintingInstructions',
    created() {
        this.scrollToTop()
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
}
</script>


<style scoped>

.tutorial {
    width: 90%;
    max-width: 1500px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.tutorial h1 {
    font-weight: 600;
}

.tutorial h2 {
    font-weight: 600;
    margin-top: 50px;
    border-top: 1px dotted lightgray;
    padding-top: 20px;
    padding: 20px 5% 0px 5%;
}

.tutorial p {
    font-size: 15px;
    line-height: 25px;
    box-sizing: border-box;
    padding: 0px 5% 0px 5%;
}

.tutorial p span {
    font-weight: 600;
}

.tutorial img {
    width: 100%;
    max-width: 300px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0px 20px 0px;
    border-radius: 5px;
}

.important {
    background-color: #D91B5B !important;
    color: white !important;
    box-sizing: border-box !important;
    padding: 2.5px !important;
    font-size: 10px !important;
    border-radius: 2.5px;
    margin-right: 5px;
}

.tutorial h5 {
    border: 1px solid #1B75BB;
    box-sizing: border-box;
    color: #1B75BB;
    padding: 5px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    text-align: center;
    /* color: gray; */
}

@media(max-width: 700px) {
    .tutorial p, .tutorial h2 {
        padding: 0px 0px 0px 0px;
    }

    .tutorial h2 {
        padding-top: 20px;
    }
}


</style>