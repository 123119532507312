/* eslint-disable no-console */

import { register } from 'register-service-worker'

// register('firebase-messaging-sw.js')







if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )

    },
    registered () {
      console.log('worker registered')
      
      
      self.addEventListener('message', event => {
        console.log(`[Message] event: `, event.data);
      });
      // addEventListener('message', event => {
      //   // event is an ExtendableMessageEvent object
      //   console.log(`The client sent me a message: ${JSON.stringify(event.data)}`);
      //   console.log(`MESSAGE BLAHHHH dasd a${event.data}`)
      //   console.log(`MESSAGE BLAHHHH dasdas ${event}`)
      //   console.log(`MESSAGE BLAHHHHd asdasd as ${event.data[0]}`)
      // //   // event.source.postMessage("Hi client");
      // });
      // if (this.store.getters.getLoggedIn === true) {
      //   console.log('user logged in')
        
      // } else {
      //   console.log('user not logged in')
      // }
      // const tokenProvider = new PusherPushNotifications.TokenProvider({
      //   url: 'http://localhost:3000/app_get_push_token',
      // })

      // console.log(tokenProvider)
      
      // const beamsClient = new PusherPushNotifications.Client({
      //   instanceId: 'a656cddd-cd5b-42f1-9db9-4f68d6993840',
      // })

      // beamsClient.start()
      // .then(() => beamsClient.addDeviceInterest('my-order-list'))
      // .then(() => console.log('Successfully registered and subscribed!'))
      // .catch(console.error);

      // beamsClient
      // .start()
      // .then((beamsClient) => beamsClient.getDeviceId())
      // .then((deviceId) =>
      //   console.log("Successfully registered with Beams. Device ID:", deviceId)
      // )
      // .catch(console.error);

      // beamsClient
      // .start()
      // .then((beamsClient) => beamsClient.getDeviceId())
      // .then((deviceId) =>
      //   console.log("Successfully registered with Beams. Device ID:", deviceId)
      // )
      // .then(() => beamsClient.addDeviceInterest("debug-me"))
      // .then(() => beamsClient.getDeviceInterests())
      // .then((interests) => console.log("Current interests:", interests))
      // .catch(console.error);

      // beamsClient.start()
      //   .then(() => beamsClient.addDeviceInterest('724815'))
      //   .catch(e => console.error('Could not add device interest', e)); 
      
      // beamsClient.start()
      //   .then(() => beamsClient.setUserId('724815', tokenProvider))
      //   .then(() => console.log('User ID has been set'))
      //   .catch(e => console.error('Could not authenticate with Beams:', e));
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
