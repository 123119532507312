<template>

    <div id="order-dashboard-container">

        <h1>My Orders</h1>

        <CurrentActivity :key="componentKey" />

        <div>

            <OrderSessions />

        </div>

        <div id="bottom-dashboard">

            <div id="order-quick-metrics">

                <OrderQuickMetrics />

            </div>

            <div id="historical-orders">

                <HistoricalOrders :current_user="currentUser" :user_type="user_type" />

                <div id="my-tables" @click="goToTables()">

                    <div class="tag new-tag blue">
                        New! 
                    </div>

                    <h3>My tables</h3>

                    <p>Generate and manage table-specific QR codes so your guests don't have to input their table number themselves</p>

                </div>

            </div>

            <div id="my-menus">

                <MyMenus />

            </div>

        </div>

    </div>

</template>

<script>

import CurrentActivity from '@/components/CurrentActivity'
import OrderQuickMetrics from '@/components/OrderQuickMetrics'
import HistoricalOrders from '@/components/HistoricalOrders'
import OrderSessions from '@/components/OrderSessions'
import MyMenus from '@/components/MyMenus'
import MyServers from '@/components/MyServers'
import { EventBus } from '../event-bus.js'
const newOrderNotifSound = require("@/assets/to-the-point.mp3");

export default {
    name: 'Orders',
    components: {
        CurrentActivity,
        OrderQuickMetrics,
        HistoricalOrders,
        MyMenus,
        OrderSessions
    },
    data () {
        return {
            componentKey: 0,
            currentUser: '',
            user_type: ''
        }
    },
    created() {
        this.getCurrentUser();
        window.scrollTo(0,0);
        if (navigator.serviceWorker) {
            this.$messaging.getToken({ vapidKey: 'BJ39NCNNKu4HRTCUskTMTFOP-yL7d2ea6XZ9wJlbCLe7qEuezIsuQHGBrltbEU1V0MKI_b09JGRV5-b5h7cDeAA' })
            .then((currentToken) => {
                if (currentToken) {
                console.log('client token', currentToken)
                this.$http.secured.post(`/app_set_fcm_token/${currentToken}`)
                    .then(response => { console.log('token set')})
                    .catch(error => { console.log('token failed to save')})
                } else {
                console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            })
        }
    },
    mounted () {
        EventBus.$on('order_received', orderReceived => {
            this.updateCurrentActivity();
        });
        EventBus.$on('help_request_received', helpRequestReceived => {
            this.updateCurrentActivity();
        });        
    },
    methods: {
        getCurrentUser() {
            this.$http.secured.get('/app_current_user')
            .then(response => { 
                this.current_user = response.data.user,
                this.user_type = response.data.user_type
                this.$store.commit('setUserType', response.data.user_type)
                localStorage.setItem('user_type', response.data.user_type)
                localStorage.setItem('user_id', Number(response.data.user))
            }).catch(error => this.setError(error, 'Something went wrong'))
        },
        registerServiceWorker() {
            if (navigator.serviceWorker) {

                navigator.serviceWorker.register('service-worker.js');
                navigator.serviceWorker.ready.then( registration => {
                    registration.active.postMessage("Hello service worker, I am from client side");
                });

            }   
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        updateCurrentActivity() {
            this.componentKey += 1;
        },
        goToTables() {
            this.$router.push('/tables')
        }
    }
}



</script>

<style scoped>

#order-dashboard-container {
    width: 90%;
    max-width: 1200px;
    margin: auto;
    margin-top: 50px;
}

#order-dashboard-container h1 {
    font-size: 30px;
    font-weight: 200;
    margin: 0px 0px 40px 0px;
}

#bottom-dashboard {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
}

#historical-orders {
    width: 60%;
    box-sizing: border-box;
    padding-right: 20px;
}

#my-menus {
    width: 40%;
    box-sizing: border-box;
    padding-left: 20px;
}

#order-quick-metrics {
    width: 100%;
    margin-bottom: 50px;
}

#my-servers {
    width: 100%;
    max-width: 600px;
}

#my-tables {
    display: flex;
    box-sizing: border-box;
    padding: 25px;
    border-radius: 10px;
    flex-direction: column;
    margin-top: 50px;
    /* box-shadow: 0 0 16px rgba(31,117, 147, 0.1); */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-bottom: 50px;
    /* border: 1px solid #27A9E1; */
}

#my-tables h3 {
    font-weight: 600;
    font-size: 30px;
    margin: 10px 0px 10px 0px;
}

#my-tables p {
    font-weight: 600;
    font-size: 12.5px;
}

@media(max-width: 900px) {
    #historical-orders {
        width: 100%;
        padding-right: 0px;
    }

    #my-menus {
        width: 100%;
        padding-left: 0px;
    }

    #my-tables {
        box-shadow: 0 0 0px rgba(0, 0, 0, 0.0);
    }

    
}



</style>