<template>

    <div class="parent-container">

        <div v-if="this.is_loading === true" class="loader">

            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div>

        <div v-else id="main-container">

            <div class="back-refer" @click="backToOrders()">Back to orders</div>

            <div class="order-header">

                <div class="status-container">

                    <div class="session-indicator">Order Session</div>

                    <div class="status-indicator open" v-if="order_session.open === true">Active</div>

                    <div class="status-indicator closed" v-if="order_session.open === false">Closed</div>

                </div>

                <div class="qr-overall-container">

                    <div class="qr-container" @click="openQrLink()">

                        <vue-qr :text="`https://menus.menulingua.com/${menu.id}/${order_session.language}/${order_session.key}`" :size="200"></vue-qr>

                    </div>

                    <div class="print-button" @click="print()">Print QR code for guest</div>

                </div>

                <h1>

                    <span>
                    
                    Order session #{{ order_session.id }}

                    </span>

                    <a v-bind:href="'/menu_settings/'+ menu.id" class="menu-box" :value="menu.id">

                        <div v-if="menu.restaurant_name != null && menu.restaurant_name != ''">
                            <img class="menu-logo" v-bind:src="menu.logo.url" /> 
                            {{ menu.restaurant_name }}
                        </div>

                        <div v-else>
                            <img class="menu-logo" v-bind:src="menu.logo.url" /> 
                            {{ menu.name }}
                        </div>

                    </a>

                </h1>

                <p>Created <span>{{ timestamp(order_session.created_at) }}</span> </p>

                <p v-if="Date.parse(order_session.expiry) > Date.now() && order_session.open === true" class="expiry-info">Expires in <span>{{ timeFromNow(Date.parse(order_session.expiry)) }}</span></p>

                <p v-else class="expiry-info">Order session closed</p>

                <div class="extend-button" @click="extendOrderSession()" v-if="((Date.parse(order_session.expiry) - Date.now()) < 3600000)">Extend Order Session</div>



                <div class="order-header-details">

                    <div>

                        <p v-if="order_session.guest_name != 'none'">Guest name: <span>{{ order_session.guest_name }}</span></p>

                        <p><img src="@/assets/table.svg" />Table number:<span>{{ order_session.table_number }}</span></p>

                        <p><img src="@/assets/pax.svg" />Pax: <span>{{ order_session.pax }}</span> </p>

                        <p><img src="@/assets/pax.svg" />Language: <span class="language-indicator">{{ order_session.language }}</span></p>

                    </div>                

                </div>

            </div>

            <div id="order-session-section-to-print">

                <OrderSessionPrintout :order_session="order_session" :menu="menu" />

            </div>


            <div v-if="order_session.open === true" class="order-status open" @click="openCloseOrderSession()">
                
                <h5>Order session active</h5>
                <p>Tap here to close</p>

            </div>
            
            <div v-else class="order-status closed" @click="openCloseOrderSession()">

                <h5>Order session closed</h5>
                <p v-if="Date.parse(order_session.expiry) > Date.now()">Tap here to reactivate</p>
                <p v-else>Order sessions that have expired cannot be reopened</p>

            </div>

            <div class="explainer">
                If an order session is <span>active</span>, the user can place orders. If an order session is <span>closed</span>, orders can no longer be placed.
            </div>

        </div>

        <div class="orders-header">

            <div class="toggle" @click="show_all_items_ordered = !show_all_items_ordered" v-if="orders.length > 0">
                {{ getVisibilityText() }}
            </div>

            <div class="no-orders-yet" v-if="orders.length === 0">No items ordered yet in this session.</div>

        </div>



        <div class="items-ordered-in-session" v-if="orders.length > 0 && show_all_items_ordered === true">

            <h3>Items ordered in this session</h3>

            <div class="full-width">

                <Order :combined_order="show_all_items_ordered" :session_order="true" :order="orders[0]" :session_order_id="orders[0].id" :additional_order_rows="items" :additional_selections="selections" :combined_subtotal="combined_subtotal" :combined_tax="combined_tax" :combined_service="combined_service" :combined_grand_total="combined_grand_total" />

            </div>

        </div>

        <div class="orders-in-session" v-if="orders.length > 0 && show_all_items_ordered === false">

            <h3>Orders in this session: {{ orders.length }}</h3>

            <div v-for="order in orders" :key="order.id" class="order-container">

                <div class="order">

                    <Order :session_order="true" :order="order" :session_order_id="order.id" />

                </div>

            </div>

            

        </div>

        <div class="delete-button-container" v-if="is_loading === false">

            <div class="delete-button" @click="deleteOrderSession()">Delete this order session</div>

        </div>

    </div>

</template>


<script>
import moment from 'moment'
import VueQr from 'vue-qr'
import Order from '@/components/Order.vue'
import OrderSessionPrintout from '@/components/OrderSessionPrintout.vue'

export default {
    name: 'OrderSession',
    components: {
        VueQr,
        Order,
        OrderSessionPrintout
    },
    data () {
        return {
            order_session: [],
            menu: [],
            orders: [],
            combined_subtotal: 0,
            combined_tax: 0,
            combined_service: 0,
            combined_grand_total: 0,
            items: [],
            is_loading: true,
            selections: [],
            show_all_items_ordered: true
        }
    },
    created() {
        this.getOrderSession();
        
    },
    methods: {
        getOrderSession() {
            const id = this.$router.currentRoute.params.id;
            this.$http.secured.get(`/app_show_order_session/${id}`)
                .then(response => { 
                    console.log(response), 
                    this.order_session = response.data.order_session, 
                    this.menu = response.data.menu
                    this.getOrdersInSession();
                    this.getItemsInSession();
                    this.is_loading = false;
                     })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        getVisibilityText() {
            if (this.show_all_items_ordered === true) {
                return 'Show individual orders in this session'
            } else {
                return 'Show all items ordered in this session'
            }
        },
        getOrdersInSession() {
            const key = this.order_session.key;
            this.$http.secured.get(`/app_get_orders_in_session/${key}`)
                .then(response => { console.log(response), this.orders = response.data.orders, this.combineOrders() })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        getItemsInSession() {
            const key = this.order_session.key;
            this.$http.secured.get(`/app_get_items_in_session/${key}`)
                .then(response => { console.log(response), this.items = response.data.items, this.selections = response.data.selections })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        combineOrders() {
            const orders = this.orders
            let combined_subtotal = 0
            let combined_tax = 0
            let combined_service = 0
            let combined_grand_total = 0
            for (let i = 0; i < orders.length; i++) {
                combined_tax = (combined_tax + Number(orders[i].calculated_order_tax))
                combined_subtotal = (combined_subtotal + Number(orders[i].price))
                combined_service = (combined_service + Number(orders[i].calculated_order_service))
                combined_grand_total = (combined_grand_total + orders[i].grand_total)
            }
            this.combined_grand_total = combined_grand_total
            this.combined_tax = combined_tax
            this.combined_service = combined_service
            this.combined_subtotal = combined_subtotal
        },
        timestamp(orderPlaced) {
            return moment(orderPlaced).format('LLL');
        },
        timeFromNow(created_at) {
            var now = Date.now()
            var created = created_at
            return this.msToTime(created - now)
        },
        msToTime(ms) {
            let seconds = (ms / 1000).toFixed(1);
            let minutes = (ms / (1000 * 60)).toFixed(1);
            let hours = (ms / (1000 * 60 * 60)).toFixed(1);
            let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
            if (seconds < 60) return seconds + " Sec";
            else if (minutes < 60) return minutes + " Min";
            else if (hours < 24) return hours + " Hrs";
            else return days + " Days"
        },
        openCloseOrderSession() {
            const id = this.$router.currentRoute.params.id;
            if (Date.parse(this.order_session.expiry) > Date.now()) {
                this.$http.secured.post(`/app_toggle_order_session_status/${id}`)
                    .then(response => { console.log(response), this.order_session = response.data.order_session, this.menu = response.data.menu })
                    .catch(error => this.setError(error, 'Something went wrong'))
            }
        },
        extendOrderSession() {
            const id = this.$router.currentRoute.params.id;
            this.$http.secured.post(`/app_extend_order_session/${id}`)
                .then(response => { this.order_session = response.data.order_session })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        openQrLink() {
            // window.location.replace(`https://localhost:8080/${this.menu.id}/${this.order_session.language}/${this.order_session.key}`)
        },
        backToOrders() {
            this.$router.replace('/');
        },
        print() {
            window.print();
        },
        deleteOrderSession() {
            if(confirm("Are you sure you want to delete this order session?")) {
                const id = this.$router.currentRoute.params.id;
                this.$http.secured.post(`/app_destroy_order_session/${id}`)
                    .then(response => { this.$router.replace('/') })
                    .catch(error => this.setError(error, 'Something went wrong'))     
            }      
        }
    }
}



</script>

<style scoped>

.parent-container {
    min-height: 100vh;
}

#main-container {
    display: flex;
    margin: auto;
    width: 90%;
    max-width: 1000px;
    flex-direction: column;
    margin-top: 50px;
}

.status-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.orders-in-session {
    max-width: 1000px;
    width: 90%;
    margin: auto;
    box-sizing: border-box;
    padding-top: 25px;
    margin-top: 50px;
    border-top: 1px dotted lightgray;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.orders-in-session h3 {
    font-weight: 200;
    font-size: 20px;
    width: 100%;
}

.status-indicator {
    margin: 0px 10px 0px 10px;
    width: max-content;
    box-sizing: border-box;
    padding: 2px 5px 2px 5px;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.5px;
}

.open {
    background-color: #fbaf3f;
    color: black;
}

.closed {
    background-color: lightgray;
    color: gray;
}

.order-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid lightgray;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 25px;
}

.order-header h1 {
    font-size: 25px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.order-header h1 span {
    margin: 5px 5px 5px 0px;
}

.order-header h1 div {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid lightgray;
    font-size: 12.5px;
    font-weight: 600;
    align-self: flex-end;
    /* margin-left: 10px; */
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    border-radius: 5px;
    cursor: pointer;
    height: 35px;
    /* margin: 10px 0px 10px 0px; */
}

.order-header h1 div:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.order-header h1 div img {
    max-height: 25px;
    max-width: 25px;
    margin-right: 5px;
}

.order-header p {
    font-size: 13px;
    margin: 15px 0px 0px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.order-header p span {
    font-weight: 600;
    margin-left: 5px;
}

.order-header-details {
    display: flex;
    flex-direction: column;
    border-top: 1px solid lightgray;
    margin-top: 15px;
    width: max-content;
}

.order-header-details p {
    margin-right: 10px;
}

.order-header-details img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.session-indicator {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px solid #00a19a;
    border-radius: 2.5px;
    width: max-content;
    box-sizing: border-box;
    padding: 2px 2px 2px 2px;
    color: #00a19a;
}

.expiry-info {
    color: gray !important;
    font-size: 10px !important;
}

.order-status {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid lightgray; */
    /* height: 100px; */
    box-sizing: border-box;
    padding: 15px;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}

.order-status h5 {
    font-weight: 600;
    margin: 0px 0px 5px 0px;
    font-size: 17.5px;
}

.order-status p {
    font-weight: 400;
    margin: 0px 0px 0px 0px;
    font-size: 12.5px;
}

.open {
    background-color: #fbaf3f;
    /* color: white; */
}

.closed {
    background-color: lightgray;
    color: gray;
}

.explainer {
    font-size: 12.5px;
    font-weight: 400;
    margin-top: 10px;
    color: gray;
    text-align: center;
}

.explainer span {
    font-weight: 600;
}

.print-button {
    /* border: 1px solid #00a19a; */
    background-color: #00a19a;
    color: white;
    font-weight: 600;
    /* text-transform: uppercase; */
    width: max-content;
    font-size: 12.5px;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    border-radius: 2.5px;
    cursor: pointer;
}

.qr-overall-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.qr-container {
    height: 100px;
    width: 100px;
}

.qr-container img {
    height: 100px;
    width: 100px;
}

.order {
    box-sizing: border-box;
    padding: 25px;
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
    border-radius: 10px;
    margin-bottom: 50px;
    border: 1px solid lightgray;
    /* background-color: #FAFAFA; */
    /* width: 45%; */
}

.items-ordered-in-session {
    width: 90%;
    max-width: 1000px;
    margin: auto;
}

.items-ordered-in-session h3 {
    font-weight: 400;
}

.order-container {
    width: 48%;
}

.extend-button {
    background-color: #00a19a;
    color: white;
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    font-size: 12px;
    width: max-content;
    margin-top: 15px;
    cursor: pointer;
    font-weight: 600;
    border-radius: 2.5px;
}

.delete-button-container {
    display: flex;
    width: max-content;
    justify-content: center;
    margin: auto;
    margin-top: 100px;
}

.delete-button {
    margin: auto;
}

#order-session-section-to-print {
    display: none;
}

.language-indicator {
    box-sizing: border-box;
    background-color: black;
    color: white;
    padding: 2.5px 10px 2.5px 10px;
    text-transform: uppercase;
    display: flex;
    font-size: 10px;
    border-radius: 2.5px;
}

.orders-header {
    width: 90%;
    max-width: 1000px;
    margin: auto;
    margin-top: 75px;
}

.toggle {
    border: 1px solid #00a19a;
    width: max-content;
    box-sizing: border-box;
    padding: 10px;
    color: #00a19a;
    font-size: 12.5px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 50px;
}

.no-orders-yet {
    font-size: 20px;
    font-weight: 200;
}

@media(max-width: 750px) {
    .order-container {
        width: 100%;
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    .orders-in-session {
        display: none !important;
    }

    #order-session-section-to-print {
        display: flex;
    }

    #order-session-section-to-print p {
        font-size: 14px;
    }

    .column-type {
        font-size: 8px !important;
    }

    #order-session-section-to-print h1 {
        font-size: 14px
    }

    #order-session-section-to-print div {
        border: 0px;
        padding: 0px;
        background-color: white;
        color: black;
    }

    #order-session-section-to-print, #order-session-section-to-print * {
        visibility: visible;
    }
    #order-session-section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}


</style>