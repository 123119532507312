<template>

    <div>

        <canvas id="line-chart"></canvas>

        <div class="menu-info">
            
            <h2>Scans in past 24 hours, by menu</h2>

            <div v-for="menu in menus" :key="menu.menu_id" class="row" @click="showUserData(menu.order_recipient_id)">

                <p class="menu-id">Menu ID: {{ menu.id }}</p>

                <p class="menu-id user-id">User ID: {{ menu.user_id }}</p>

                <p class="menu-id order-recipient-id">Order Recipient ID: {{ menu.order_recipient_id }}</p>

                <p class="menu-name">{{ menu.name }}</p>
                
                <p class="scan-count">{{ menu.scan_count }}<span>scans</span></p>

                <p v-if="menu.menu_builder_v2 === true" class="menu-builder-version v2">MENU BUILDER V2</p>

                <p v-else class="menu-builder-version v1">MENU BUILDER V1</p>

            </div>

        </div>

    </div>

    




</template>

<script>
import Chart from 'chart.js'
import moment from 'moment'

export default {
    name: 'UsageChart',
    props: ['scans', 'orders', 'dates', 'menus'],
    data() {
        return {

        }
    },
    mounted() {
        this.generateChart()
    },
    methods: {
        redirectToMenu(menu_id) {
            window.location.replace(`https://menus.menulingua.com/${menu_id}`)
        },
        showUserData(order_recipient_id) {
            this.$emit('show-user-data', order_recipient_id)
        },
        generateChart() {
            // const labels = this.generateDates();
            const orders = this.orders
            const scans = this.scans
            const dates_array = this.dates
            const ctx = document.getElementById('line-chart');
            const exampleChart = new Chart(ctx, {
                type: "line",
                data: {
                    labels: dates_array,
                    datasets: [
                        {
                            label: "Number of scans",
                            data: scans,
                            backgroundColor: "rgba(0, 161, 154, 0.5)"
                        },
                        {
                            label: "Number of orders",
                            data: orders,
                            backgroundColor: "rgba(60, 60, 59, 0.5)"
                        }
                    ]
                },
                options: {
                    responsive: true,
                    lineTension: 10,
                    legend: {
                        display: false
                    },
                    scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                padding: 0,
                                fontFamily: "Montserrat",
                                
                            },
                            gridLines: {
                                drawBorder: false,
                                color: "rgba(0, 0, 0, 0)"
                            }
                        },
                    ],
                    xAxes:[
                        {
                            ticks: {
                                autoSkip: true,
                                maxRotation: 0,
                                minRotation: 0,
                                fontFamily: "Montserrat",
                                fontWeight: "bold"
                                
                            },
                            gridLines:{
                                zeroLineColor:'transparent',
                                color: "rgba(0, 0, 0, 0)",
                        }
                    }]
                    }
                }
            });
        }
    }
}





</script>

<style scoped>

.menu-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    border-top: 1px dotted lightgray;
    justify-content: space-between;

}

.menu-info h2 {
    font-weight: 600;
    font-size: 15px;
    width: 100%;
}

.row {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 20px 0px;
    width: 30%;
    /* border: 1px solid gray; */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
}

.menu-name {
    font-size: 12.5px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}

.menu-id {
    font-size: 8px;
    font-weight: 600;
    width: max-content;
    background-color: #C18BC1;
    color: white;
    margin: 0px 0px 2.5px 0px;
    letter-spacing: 0.5px;
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    border-radius: 2.5px;
}

.user-id {
    background-color: #78ABCE;
}

.order-recipient-id {
    background-color: #D880A2;
}

.scan-count {
    margin: 10px 0px 5px 0px;
    font-weight: 600;
    font-size: 20px;
    color: #4394ce;
}

.scan-count span {
    font-size: 10px;
    font-weight: 400;
    margin-left: 5px;
}

.menu-builder-version {
    display: flex;
    flex-direction: row;
    width: max-content;
    font-size: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    color: white;
    box-sizing: border-box;
    margin: 0px 0px 0px 0px;
    padding: 2.5px 5px 2.5px 5px;
}

.v1 {
    background-color: lightgray;
}

.v2 {
    background-color: #8CC63E;
}

@media(max-width: 600px) {
    .row {
        width: 100%;
    }
}


</style>