<template>

    <div id="servers">

        <h2>My servers</h2>

        <h6>Enable your staff to place orders.</h6>

        <div v-if="this.is_loading === true" class="loader">

            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div>

        <div v-else>

            <div v-if="servers.length < 1">

                No servers yet

            </div>

            <div v-else class="servers-container">

                <div v-for="server in servers" :key="server.id" class="server" @click="editServer(server.id)" :class="{'active' : server.active === true }">

                    <img src="../assets/check_mark_white.svg" v-if="server.active === true" />

                    <img src="../assets/x_button.svg" v-if="server.active === false" />

                    <div>

                        <p class="server-email">{{ server.email }}</p>
                    
                        <p class="server-name">{{ server.server_name }}</p>

                    </div>

                </div>

            </div>

            <div @click="newServer()" class="button">

                <p>Create a server</p>

            </div>


        </div>

    </div>


</template>


<script>

export default {
    name: 'MyServers',
    data () {
        return {
            servers: [],
            is_loading: true
        }
    },
    created() {
        if (!localStorage.signedIn) {
            this.$router.replace('/')
        } else {
        this.$http.secured.get('/app_get_my_servers')
            .then(response => { this.servers = response.data, this.is_loading = false })
            .catch(error => this.setError(error, 'Something went wrong'))
        }  
    },
    methods: {
        newServer() {
            this.$router.replace('/new_server')
        },
        editServer(id) {
            this.$router.replace(`/servers/${id}`)
        }
    }
}


</script>

<style scoped>

#servers {
    width: 100%;
    margin-top: 50px;

}

h2 {
    font-weight: 600;
    width: 100%;
}

h6 {
    width: 100%;
    font-size: 15px;
    margin: 0px 0px 20px 0px;
    font-weight: 400;
    border-bottom: 1px dotted gray;
    box-sizing: border-box;
    padding: 2.5px 0px 2.5px 0px;
    width: max-content;
}

.server {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    align-items: center;
    margin: 0px 0px 10px 0px;
    width: 49%;
    background-color: lightgray;
    color: gray;
}

.server img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.servers-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.server-name {
    margin: 0px 0px 0px 0px;
    font-size: 12.5px;
    font-weight: 600;
}

.active {
    background-color: #8cc63e;
    color: white;
}

.server-email {
    font-size: 8px;
    box-sizing: border-box;
    margin: 0px 0px 5px 0px;
    border-radius: 2.5px;
    width: max-content;
    text-transform: uppercase;
    font-weight: 600;
}

.button {
    border: 1px solid #00a19a;
    color: #00a19a;
    border-radius: 2.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px 0px 20px;
    cursor: pointer;
    margin-top: 50px;
    font-size: 12.5px;
    font-weight: 600;
    width: max-content;
}

</style>