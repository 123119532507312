<template>

  <div class="quickdata-container">

      <div class="quickdata-box orders-today">

          <div v-if="this.is_loading === true" class="loader">

              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

          </div>

          <div v-else>

              <h3>Orders today</h3>

              <h4>{{ order_count_today }}</h4>
              
              <transition name="slide-fade" mode="out-in">
                  <p :key="order_value_today">
                      {{ currency }} {{ numberFormat(order_value_today) }} total value
                  </p>
              </transition>

          </div>

      </div>

      <div class="quickdata-box orders-this-week">

          <div v-if="this.is_loading === true" class="loader">

              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

          </div>

          <div v-else>

              <h3>Orders since Monday, {{ moment(getPreviousMonday()).format('MMM D') }}</h3>

              <h4>{{ order_count_this_week }}</h4>

              <transition name="slide-fade" mode="out-in">
                  <p :key="order_value_this_week">
                     {{ currency }} {{ numberFormat(order_value_this_week) }} total value
                  </p>
              </transition>

          </div>

      </div>

      <div class="quickdata-box orders-this-month">

          <div v-if="this.is_loading === true" class="loader">

              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

          </div>

          <div v-else>

              <h3>Orders since {{ getNameOfCurrentMonth() }} 1st</h3>

              <h4>{{ order_count_this_month }}</h4>

              <transition name="slide-fade" mode="out-in">
                  <p :key="order_value_this_month">
                      {{ currency }} {{ numberFormat(order_value_this_month) }} total value
                  </p>
              </transition>

          </div>

      </div>




  </div>


</template>

<script>
// import { CountUp } from 'countup.js';
import { EventBus } from '@/event-bus.js'
import moment from 'moment'

export default {
  name: 'OrderQuickMetrics',
  data() {
      return {  
          orderData: {},
          is_loading: true,
          order_count_today: 0,
          order_count_this_week: 0,
          order_count_this_month: 0,
          order_value_today: 0,
          order_value_this_week: 0,
          order_value_this_month: 0,
          currency: ''
      }
  },
  created () {
      if (!localStorage.signedIn) {
          this.$router.replace('/')
      } else {
      this.$http.secured.get('/app_get_order_recent_history')
          .then(response => { 
              this.is_loading = false,
              this.orderData = response.data, 
              this.currency = this.orderData.currency,
              this.order_count_today = this.orderData.orders_today,
              this.order_count_this_week = this.orderData.orders_this_week,
              this.order_count_this_month = this.orderData.orders_this_month,
              this.order_value_today = this.orderData.order_value_today,
              this.order_value_this_week = this.orderData.order_value_this_week,
              this.order_value_this_month = this.orderData.order_value_this_month
              })
          .catch(error => this.setError(error, 'Something went wrong'))
      }
  },
  mounted() {
      EventBus.$on('order_received', orderReceived => {
          // console.log(this.orderReceived);
          this.order_count_today = this.order_count_today + 1
          this.order_count_this_week = this.order_count_this_week + 1
          this.order_count_this_month = this.order_count_this_month + 1
          
          this.$http.secured.get('/app_get_order_recent_history')
          .then(response => { 
              this.orderData = response.data, 
              this.order_value_today = this.orderData.order_value_today,
              this.order_value_this_week = this.orderData.order_value_this_week,
              this.order_value_this_month = this.orderData.order_value_this_month 
              })
          .catch(error => this.setError(error, 'Something went wrong'))
      });
  },
  methods: {
      numberFormat(number) {
          if (number != null) {
              if (this.orderData.currency === 'OMR') {
                  return Number(number).toFixed(3)
              } else if (this.orderData.currency === 'Rp') {
                  return Number(number.toFixed(0)).toLocaleString()
              } else {
                  return Number(number).toFixed(2).toLocaleString()
              }
          } else {
              return "--"
          }
      },
      getNameOfCurrentMonth() {
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ]
        const d = new Date();
        return monthNames[d.getMonth()]
      },
      getPreviousMonday() {
        var prevMonday = new Date();
        return prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
      },
      setError (error, text) {
          this.error = (error.response && error.response.data && error.response.data.error) || text
      }
  }
}



</script>


<style scoped>

.quickdata-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.orders-today {
  box-shadow: 0 0 10px rgba(50, 169, 222, 0.2);
}

.orders-this-week {
  box-shadow: 0 0 10px rgba(157, 82, 150, 0.2);
}

.orders-this-month {
  box-shadow: 0 0 10px rgba(140, 198, 62, 0.2)
}

.quickdata-box {
  width: 32%;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  /* border: 1px solid lightgray; */
  height: 161px;
}

/* .quickdata-box:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
} */

.quickdata-box h3 {
  font-weight: 600;
  font-size: 15px;
  margin: 0px 0px 10px 0px;
}

.quickdata-box h4 {
  font-weight: 400;
  margin: 10px 0px 30px 0px;
  font-size: 35px;
}

.quickdata-box p {
  margin: 0px 0px 10px 0px;
  box-sizing: border-box;
  /* padding: 2.5px; */
  font-size: 12.5px;
  font-weight: 600;
  /* border: 1px solid lightgray; */
  /* border-radius: 5px; */
}

.slide-fade-enter-active {
transition: all .1s ease;
}
.slide-fade-leave-active {
transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
transform: translateX(10px);
opacity: 0;
}

@media(max-width: 750px) {
  .quickdata-container {
      flex-direction: column;
  }

  .quickdata-box {
      width: 100%;
      margin: 10px 0px 10px 0px;
      height: 120px;
  }

  .quickdata-box h3 {
      font-size: 12.5px;
  }

  .quickdata-box h4 {
      font-size: 22.5px;
      margin: 0px 0px 10px 0px;
      font-weight: 400;
  }

  .quickdata-box p {
      font-size: 12.5px;
      margin: 0px 0px 0px 0px;
  }
}




</style>