<template>

    <div id="activity-container">

        <div class="orders-main-container">

            <div class="current-orders-container unacknowledged">
                
                <h3>Current activity</h3>

                <div class="unacknowledged-orders-container" v-if="unacknowledgedOrdersExist()">
                    
                    <div @click="acknowledgeHelpRequest(unacknowledged_help_request.id)" class="help-request-box unacknowledged-help-request-box" :value="unacknowledged_help_request.id" v-for="unacknowledged_help_request in unacknowledged_help_requests" :key="unacknowledged_help_request.id">

                        <h4>Help requested <img src='@/assets/arrow_forward_wide.svg' /></h4>

                        <p>{{ timestamp(unacknowledged_help_request.created_at) }} <img src="@/assets/table_orange.svg" /> {{ unacknowledged_help_request.table_number }} </p>

                        <p class="help-request-note">{{ unacknowledged_help_request.note }} </p>

                    </div>

                    <a v-bind:href="'/orders/'+ unacknowledged_order.id" class="order-box unacknowledged-order-box" :value="unacknowledged_order.id" v-for="unacknowledged_order in unacknowledged_orders" :key="unacknowledged_order.id">

                        <h4>New order received <img src='@/assets/arrow_forward_wide.svg' /></h4>

                        <p>{{ timestamp(unacknowledged_order.created_at) }}
                            <span v-if="unacknowledged_order.order_type === 'in-restaurant'">
                                <img src="@/assets/table_blue.svg" /> 
                                {{ unacknowledged_order.table_number }}
                            </span>
                            <span v-else> 
                                {{ unacknowledged_order.order_type }}
                            </span>
                        </p>

                    </a>

                    

                </div>

                <div v-else>

                    <div class="no-orders">

                        <img src="@/assets/order_plate_black.svg" />No orders right now

                    </div>

                    <div class="no-orders">

                        <img src="@/assets/bell_black.svg" />No active help requests right now

                    </div>                    

                </div>

            </div>

            <div class="current-orders-container acknowledged">
                
                <h3>Activity in the past hour</h3>

                <div class="acknowledged-orders-container" v-if="acknowledgedOrdersExist()">

                    <a class="help-request-box acknowledged-help-request-box" :value="acknowledged_help_request.id" v-for="acknowledged_help_request in acknowledged_help_requests" :key="acknowledged_help_request.id">

                        <p>{{ timestamp(acknowledged_help_request.created_at) }} <img src="@/assets/table_light_orange.svg" /> {{ acknowledged_help_request.table_number }} </p>

                        <p class="help-request-note">{{ acknowledged_help_request.note }} </p>

                    </a>

                    <a v-bind:href="'/orders/'+ acknowledged_order.id" class="order-box acknowledged-order-box" :value="acknowledged_order.id" v-for="acknowledged_order in acknowledged_orders" :key="acknowledged_order.id">

                        <p>
                            <span v-if="acknowledged_order.open === true" class="open-order-circle"></span>
                            {{ timestamp(acknowledged_order.created_at) }}
                            <span v-if="acknowledged_order.order_type === 'in-restaurant'">
                                <img src="@/assets/table_gray.svg" /> 
                                {{ acknowledged_order.table_number }}
                            </span>
                            <span v-else> 
                                {{ acknowledged_order.order_type }}
                            </span>
                        </p>

                        <img class="acknowledged-order-arrow" src='@/assets/arrow_forward.svg' />

                    </a>

                </div>

                <div v-else>

                    <div class="no-orders">

                        <img src="@/assets/order_plate_black.svg" />No orders in past hour

                    </div>

                    <div class="no-orders">

                        <img src="@/assets/bell_black.svg" />No help requests in past hour

                    </div>   

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import moment from 'moment';
import { EventBus } from '../event-bus.js'


export default {
    name: 'CurrentActivity',

    data () {
        return {
            acknowledged_orders: [],
            unacknowledged_orders: [],
            acknowledged_help_requests: [],
            unacknowledged_help_requests: []
        }
    },
    created () {
        if (!localStorage.signedIn) {
            this.$router.replace('/')
        } else {
            this.getOrders();
        }
    },
    methods: {
        getOrders() {
            this.$http.secured.get('/app_acknowledged_orders')
            .then(response => { this.acknowledged_orders = response.data.current_orders, this.acknowledged_help_requests = response.data.help_requests })
            .catch(error => this.setError(error, 'Something went wrong'))

            this.$http.secured.get('/app_unacknowledged_orders')
            .then(response => { this.unacknowledged_orders = response.data.current_orders, this.unacknowledged_help_requests = response.data.help_requests })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        timestamp(orderPlaced) {
            return moment(orderPlaced).format('LT');
        },
        acknowledgeHelpRequest(help_request_id) {
            this.$http.secured.post('/app_acknowledge_help_request/' + help_request_id)
            .then(response => { 
                this.getOrders(),
                EventBus.$emit('order_seen', this.orderSeen); })
            .catch(error => this.setError(error, 'Something went wrong'))
        },
        unacknowledgedOrdersExist() {
            if ((this.unacknowledged_orders.length > 0) || (this.unacknowledged_help_requests.length > 0)) {
                return true
            } else {
                return false
            }
        },
        acknowledgedOrdersExist() {
            if ((this.acknowledged_orders.length > 0) || (this.acknowledged_help_requests.length > 0)) {
                return true
            } else {
                return false
            }
        }
    }
}


</script>

<style scoped>

#activity-container {
    width: 100%;
    margin: 0px 0px 20px 0px;
}

#activity-container h2 {
    font-weight: 600;
    font-size: 17px;
    margin: 0px 0px 10px 0px;
}

.orders-main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.current-orders-container {
    display: flex;
    flex-direction: column;
}

.unacknowledged {
    width: 60%;
    box-sizing: border-box;
    padding-right: 20px;
}

.acknowledged {
    width: 40%;
    box-sizing: border-box;
    padding-left: 20px;
}

.current-orders-container h3 {
    margin: 0px 0px 20px 0px;
    font-weight: 600;
}

.unacknowledged-orders-container {
    display: flex;
    flex-direction: column-reverse;    
}

.acknowledged-orders-container {
    display: flex;
    flex-direction: column-reverse;
}

.unacknowledged-orders-container > :last-child {
    height: 120px;
    transition: 0.5s;
    animation: fadein 0.5s;
}

@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

.unacknowledged-order-box {
    background-color: #1b75bb;
    color: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    height: 80px;
}

.unacknowledged-order-box:hover {
    background-color: #5588c6;
    transition: 0.2s;
}

.unacknowledged-order-box h4 {
    font-size: 15px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.acknowledged-order-box {
    background-color: #a7a9ab;
    /* border: 1px solid #a7a9ab; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    color: white;
    height: 50px;
    max-height: 50px;
}

.acknowledged-order-box:hover {
    /* background-color: #bfbfc0; */
    transition: 0.5s;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.acknowledged-order-arrow {
    width: 30px;
}

.order-box h4 img {
    width: 50px;
}

.no-orders {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
}

.no-orders img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.order-box p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
}

.order-box p span {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    margin-left: 5px;
    margin: 0px 5px 0px 10px;
}

.order-box p img {
    height: 15px;
    width: 15px;
    margin: 0px 5px 0px 0px;
}

.help-request-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    color: white;
}

.help-request-box p {
    font-size: 12.5px;
    font-weight: 600;
    margin: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
}

.help-request-box h4 {
    font-size: 15px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.help-request-box h4 img {
    width: 50px;
}

.help-request-box p img {
    height: 15px;
    width: 15px;
    margin: 0px 5px 0px 10px;
}

.help-request-note {
    max-width: 400px;
    margin-left: 30px !important;
}

.acknowledged-help-request-box {
    background-color: #d7b995;
}

.unacknowledged-help-request-box {
    background-color: #fbaf3f;
    cursor: pointer;
}

.unacknowledged-help-request-box:hover {
    background-color: #d7b995;
    transition: 0.2s;
}

.open-order-circle {
    background-color: #fbaf3f;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* border: 1px dotted white; */
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
    margin: 0px 10px 0px 0px !important;
}

@media(max-width: 900px) {
    
    .unacknowledged {
        width: 100%;
        padding-right: 0px;
    }

    .acknowledged {
        width: 100%;
        padding-left: 0px;
        margin-top: 50px;
    }
}

</style>