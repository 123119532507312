<template>

    <div id="create-container">

        <div v-if="this.is_loading === true" class="loader">

            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

        </div>

        <div v-else>

            <div class="back-button transparent-blue blue-text" @click="returnToTables()">
                <img src="@/assets/back_arrow_blue.svg" />
                Tables
            </div>

            <h1 v-if="editing === true">Table: {{ table_number }}</h1>

            <h1 v-else>Create a table</h1>

            <div class="order-session-creation-modal">

                <div class="form-row" v-if="user_type === 'admin'">

                    <h4>Enter order recipient ID (admin only)</h4>

                    <input @input="getMenus(order_recipient_id), saveId(order_recipient_id)" v-model="order_recipient_id" required>

                </div>

            </div>

            <p class="error">{{ error }}</p>

            <div v-if="editing === true" class="menus-container">

                <h2>QR codes</h2>

                <p>These QR codes include table number information</p>

                <div v-for="outlet_collection in outlet_collections" :key="outlet_collection.id" class="qr-container outlet-collection">

                    <div class="tag blue">Outlet collection</div>

                    <p>{{ outlet_collection.name }}</p>

                    <vue-qr :text="`https://${outlet_collection.custom_url}.menulingua.com?table=${table_number}`" :size="200"></vue-qr>

                </div>
            
                <div v-for="menu in menus" :key="menu.id" class="qr-container menu">

                    <div class="tag green">Menu</div>

                    <p>{{ menu.name }}</p>

                    <vue-qr :text="`https://menus.menulingua.com/${menu.id}?table=${table_number}`" :size="200"></vue-qr>
                    
                </div>

            </div>

            <div class="order-session-creation-modal">

                <div class="form-row">

                    <h4>Enter table number</h4>

                    <input v-model="table_number" required>

                </div>

                <div @click="submitTable()" class="create-button" v-if="table_number != ''">Submit</div>

                <div class="delete-button" v-if="editing === true" @click="deleteTable()">Delete table</div>

            </div>

        </div>

    </div>


</template>


<script>
import VueQr from 'vue-qr'

export default {
    name: 'CreateTable',
    props: ['editing', 'id'],
    components: {
        VueQr
    },
    data () {
        return {
            table_number: '',
            deleted: false,
            user_id: Number,
            order_recipient_id: '',
            user_type: '',
            menus: [],
            is_loading: true,
            outlet_collections: [],
            error: ''
        }
    },
    created() {
        this.scrollToTop();
        this.getUserInfo();
        this.getMenus(this.order_recipient_id);
        if (this.editing === true) {
            this.getTable(this.id)
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        // downloadQrCode(dataUrl,id){
        //     console.log(dataUrl, id)
        //     window.location.href = dataUrl
        // },
        getUserInfo() {
            this.user_type = this.$store.getters.getUserType || localStorage.user_type
            this.user_id = Number(localStorage.user_id)
            if (this.$store.getters.getCurrentlyViewedUser != 0) {
                this.order_recipient_id = this.$store.getters.getCurrentlyViewedUser
            } else {
                this.order_recipient_id = Number(this.user_id)
            }
        },
        getTable(id) {
            this.$http.secured.get(`/app_get_table/${id}`)
                .then(response => { this.table_number = response.data.table_number, this.order_recipient_id = response.data.order_recipient_id, this.getMenus(this.order_recipient_id) })
                .catch(error => this.setError(error, 'Something went wrong'))
        },
        getMenus(order_recipient_id) {
            this.is_loading = true
            this.$http.secured.get(`/app_get_my_order_recipient_menus/${order_recipient_id}`)
                .then(response => { this.menus = response.data.menus, this.outlet_collections = response.data.outlet_collections, this.is_loading = false, this.error = '' })
                .catch(error => this.setError(error, 'Something went wrong'), this.is_loading = false, this.error = 'No menus found', this.menus = [])
        },
        submitTable() {
            if (this.editing === true) {
                this.$http.secured.post(`/app_update_table/${this.id}`, { restaurant_table: {
                    table_number: this.table_number,
                    order_recipient_id: this.order_recipient_id,
                    deleted: this.deleted
                }})
                    .then(response => { console.log(response), this.$router.replace(`/tables`) })
                    .catch(error => this.setError(error, 'Something went wrong'))    
            } else {   
                this.$http.secured.post(`/app_create_table/`, { restaurant_table: {
                    table_number: this.table_number,
                    order_recipient_id: this.order_recipient_id,
                    deleted: this.deleted
                }})
                    .then(response => { console.log(response), this.$router.replace(`/tables`) })
                    .catch(error => this.setError(error, 'Something went wrong'))
            }
        },
        returnToTables() {
            this.$router.replace('/tables')
        },
        saveId(order_recipient_id) {
            this.$store.commit('setCurrentlyViewedUser', Number(order_recipient_id))
        },
        deleteTable() {
            if(confirm("Are you sure you want to delete this table?")) {
                (this.deleted = true).then(this.submitTable());
            }
        }
    }
}

</script>


<style scoped>

#create-container {
    margin: auto;
    margin-top: 100px;
    margin-bottom: 50px;
    max-width: 1000px;
    width: 90%;

}

#create-container h1 {
    font-size: 30px;
    font-weight: 200;
    margin: 0px 0px 40px 0px;
}


.form-selector {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-selector h4 {
    width: 100%;
    
}

.form-selector div {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    color: gray;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 2.5px;
    cursor: pointer;
    margin: 5px 5px 5px 0px;
    font-size: 12.5px;
}

.selected-option {
    background-color: #00a19a;
    color: white !important;
    border: 1px solid #00a19a !important;
    transition: 0.2s;
}

.order-session-creation-modal h4 {
    font-weight: 600;
    border-bottom: 1px dotted lightgray;
    width: max-content;
}

.form-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0px 0px 0px 0px;
    /* border-bottom: 1px solid lightgray; */
}

input {
    width: 100%;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    height: 45px;
    font-size: 25px;
    border: 1px solid lightgray;
    font-weight: 200;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
}

.create-button {
    width: 100%;
    display: flex;
    margin-top: 25px;
    color: white;
    background-color: #00a19a;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    font-weight: 600;
    border-radius: 2.5px;
    cursor: pointer;
}

.error {
    font-size: 12.5px;
    font-weight: 600;
    color: darkred;
    height: 20px;
    width: 100%;
}

.button {
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: lightgray;
    color: gray;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.button p {
    margin: 0px 0px 0px 0px;
    font-weight: 600;
}

.button img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.active {
    background-color: #8cc63e;
    color: white;
}

.delete-button {
    margin-top: 50px;
}

.menus-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.menus-container h2 {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
}

.menus-container p {
    font-weight: 600;
    font-size: 12.5px;
    width: 100%;
}

.qr-container {
    width: max-content;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    margin-right: 30px;
    margin-bottom: 30px;
    min-width: 150px;
    cursor: pointer;
}

.tag {
    width: max-content;
}

.qr-container p {
    font-weight: 600;
    font-size: 12.5px;
}

@media(max-width: 600px) {
    .qr-container {
        width: 100%;
        margin-right: 0px;
        height: auto;
        box-shadow: 0 0 0px;
        border-bottom: 1px dotted lightgray;
        border-radius: 0px;
        align-items: flex-start;
    }
}


</style>



