// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import store from './store'
import Login from '@/components/Login'
import Orders from '@/components/Orders'
import CurrentActivity from '@/components/CurrentActivity'
import HistoricalOrders from '@/components/HistoricalOrders'
import { EventBus } from './event-bus.js'
import Order from '@/components/Order'
import MenuSettings from '@/components/MenuSettings'
import moment from 'moment'
import VueTelInput from 'vue-tel-input'
import Analytics from '@/components/Analytics'
import Discounts from '@/components/Discounts'
import AddDiscount from '@/components/AddDiscount'
import Discount from '@/components/Discount'
import OrderSession from '@/components/OrderSession'
import PrintingInstructions from '@/components/PrintingInstructions'
import CreateOrderSession from '@/components/CreateOrderSession'
import CreateServer from '@/components/CreateServer'
import EditServer from '@/components/EditServer'
import CreateTable from '@/components/CreateTable'
import EditTable from '@/components/EditTable'
import MyTables from '@/components/MyTables'
import 'vue-tel-input/dist/vue-tel-input.css'
import { plainAxiosInstance, securedAxiosInstance } from './backend/axios'
import './registerServiceWorker'
// import * as PusherPushNotifications from "@pusher/push-notifications-web";
import firebase from './firebase'


Vue.config.productionTip = false

if (firebase.messaging.isSupported()) {
  console.log('firebase is supported')
  Vue.prototype.$messaging = firebase.messaging()
} else {
  console.log('firebase is not supported')
}

Vue.prototype.moment = moment

Vue.use(VueRouter);
Vue.use(VueTelInput);
// Vue.prototype.$bus = EventBus

Vue.use(require('vue-pusher'), {
    api_key: '04617d2b129b1b5a2f05',
    options: {
        cluster: 'ap1',
        encrypted: true,
    }
});


Vue.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance
})

export const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: "/", component: Login },
        { path: "/printing", component: PrintingInstructions },
        { path: "/orders", component: Orders },
        { path: "/current_activity", component: CurrentActivity },
        { path: "/orders/:id", component: Order },
        { path: "/menu_settings/:id", component: MenuSettings },
        { path: "/analytics", component: Analytics },
        { path: "/:menu_id/new_discount", component: AddDiscount },
        { path: "/order_sessions/new", component: CreateOrderSession },
        { path: "/order_sessions/:id", component: OrderSession },
        { path: "/servers/:id", component: EditServer },
        { path: "/new_server", component: CreateServer },
        { path: "/tables/:id", component: EditTable },
        { path: "/new_table", component: CreateTable },
        { path: "/tables", component: MyTables }

    ]
});


new Vue({
  el: '#app',
  store,
  securedAxiosInstance,
  plainAxiosInstance,
  router,
  components: { App },
  template: '<App/>'
})

